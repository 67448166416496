<template>
  <div class="">
    <Breadcrumb />
    <Intro msg="Modifica evento" />
    <UpdateTheEvent />
  </div>
</template>

<script>
import Intro from "@/components/component/Intro.vue";
import Breadcrumb from "@/components/component/Breadcrumb.vue";
import UpdateTheEvent from "@/components/events/Update.vue";

export default {
  name: "UpdateEvent",
  components: {
    Intro,
    Breadcrumb,
    UpdateTheEvent,
  },
};
</script>
