import firebase from "../firebase";

const db = firebase.collection("/email");

class SendEmailDataService {
    // getAll() {
    //     return db;
    // }

    create(event) {
        return db.add(event);
    }

    // read(id) {
    //     return db.doc(id).get();
    // };

    // update(id, value) {
    //     return db.doc(id).update(value);
    // }

    // delete(id) {
    //     return db.doc(id).delete();
    // }
}

export default new SendEmailDataService();