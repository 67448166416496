<template>
  <div class="">
    <div class="container px-4 mx-auto">
      <a
        class="block text-body uppercase cursor-pointer mb-6"
        @click="$router.go(-1)"
        >{{ back }}</a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "Breadcrumb",
  data() {
    return {
      back: "< indietro",
    };
  },
};
</script>

<style scoped lang="scss">
</style>
